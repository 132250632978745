import React from 'react'
import { Icon, Image } from 'semantic-ui-react';

const AccountPicture = props => {
    return (
        (props.account?.pictureUrl) ? 
            <Image size='mini' src={props.account.pictureUrl} referrerPolicy='no-referrer' /> : 
            <Icon bordered inverted color='black' name='user' />
        )
}

export default AccountPicture;