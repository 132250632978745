class ContentType {
    static DATASET = 'application/vnd.dataset+json';
    static TEXT = 'text/plain';
    static HTML = 'text/html';
    static MARKDOWN = 'text/markdown';

    static DISPLAY_TYPES =  [this.TEXT, this.HTML, this.MARKDOWN];

    static isDisplayType(type) {
        return this.DISPLAY_TYPES.indexOf(type) !== -1;
    }
}

export default ContentType;