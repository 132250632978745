import React, { useEffect, useRef, useState } from 'react';
import { Message, MessageHeader } from 'semantic-ui-react';


const AdContent = props => {
  const adUri = `${process.env.REACT_APP_REST_URI}/ad`;
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading) return;
    setLoading(true)

  fetch(
    adUri,
    {
        method: "GET",
        credentials: "include"
    }
    ).then(response => response.text())
    .then(data => setContent(data))
  
  })

    return (<Message color='blue' style={{margin: '1em'}} compact>
      <MessageHeader>Ad</MessageHeader>
      <div dangerouslySetInnerHTML={{__html:content}} />
      </Message>)
}

export default AdContent;