import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Pagination } from "semantic-ui-react";
import Auth from './Auth';
import Extent from './Extent';
import Hideable from './Hideable';
import RecordSearch from './RecordSearch';
import SubjectContext from './SubjectContext';

const PAGE_SIZE = 10;

const items = (data, readOnly, account) => {
  return (<div>
    {data?.map(o => {
      const editable = !readOnly && (account.id === o.reporter || account?.roles?.includes("POWER"));
      return (<div key={o.id}>
        <SubjectContext account={account} subject={{ type: 'RECORD', id: o.id, label: o.title }} /><br />
        <Extent value={o.extent} /><br />
        <Hideable hidden={!editable}>
          <a href={`/report/edit/${o.id}`}>Edit</a>
        </Hideable>
        <hr />
      </div>)
    }
    )}
  </div>)
}


const Reports = props => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1)

  const [observations, setObservations] = useState(null)
  document.title = `navscout - Reports`;

  const totalPages = observations ? Math.max(1, Math.ceil(observations.countAvailable / PAGE_SIZE)) : 0;

  return (
    <div>
      <RecordSearch page={page} pageSize={PAGE_SIZE} client={props.client} onUpdate={(o, d) => {
        if (d) {
          setPage(1)
        }
        setObservations(o)
      }} />
      <div style={{ paddingTop: '1em' }}>
        {items(observations?.observations, !props.account, props.account)}
        <Pagination activePage={page} totalPages={totalPages} onPageChange={(e, d) => setPage(d.activePage)} />
      </div>
      <Hideable hidden={!Auth.canReport(props.account)}>
        <Form>
          <Form.Field>
            <Form.Button onClick={() => navigate('/report/add')}>Add Report</Form.Button>
          </Form.Field>
        </Form>
      </Hideable>
    </div>)
}

export default Reports;