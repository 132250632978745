import React, { useState } from 'react';
import { Button, Form, Label, Message, Modal } from 'semantic-ui-react';
import Hideable from './Hideable';

const MIN_LENGTH = 8;

const AccountPasswordUpdate = props => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState("");

  let message = null;

  if (password === null || password.length < MIN_LENGTH) {
    message = "Your password must be atleast 8 characters"
  } else if (password !== verify) {
    message = "Your password must match the verification"
  }


  const close = () => { 
    setOpen(false); 
    if (props.clearOnClose) {
      setPassword(""); 
      setVerify(""); 
    }
  }
  
  return <Modal
    trigger={<Button 
      style={{margin:'0 auto 0 auto'}}
      primary={true}
      onClick={() => setOpen(true)}
      >Update Password</Button>}
    open={open}
    onClose={() => close() }
    >
    <Modal.Header>Update Password</Modal.Header>
    <Modal.Content>
        <Form onSubmit={e => {
            e.preventDefault();
            props.onSubmit({password});
            close();
          }}>
          <Form.Field>
            <Label>New password</Label>
            <Form.Input type="password" value={password} name="password" onChange={e => {e.preventDefault(); setPassword(e.target.value)}} />
          </Form.Field>
          <Form.Field>
            <Label>Verify your password</Label>
            <Form.Input type="password" value={verify} name="verify" onChange={e => {e.preventDefault(); setVerify(e.target.value)}} />
          </Form.Field>
          <Button disabled={message !== null}>{props.submit||'Submit'}</Button>
          <Hideable hidden={props.onDelete ? false : true}>
            <Button onClick={() => {props.onDelete();close();} }>Delete</Button>
          </Hideable>
          <br/>
          <Hideable hidden={message === null}>
            <Message
              negative
              header='Password check failed'
              content={message}
              />
          </Hideable>
          <br/>
        </Form>
    </Modal.Content>
    </Modal>;
}

export default AccountPasswordUpdate;