import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Container, Header, Icon, Image } from 'semantic-ui-react';
import './App.css';
import { Link } from 'react-router-dom';

const About = props => {
    return (
        <Container fluid>
            <Image src='/image/navscout-logo-512.png' />
            Version: {process.env.REACT_APP_VERSION || 'Unknown'}
            {/* &nbsp; */}
            {/* <a href='javascript:window.location.reload()'>Update</a> */}
            <br/>
            Platform: {process.env.REACT_APP_PLATFORM || 'Unknown'}
            <h1>Information</h1>
            <Link to='/about/privacy'>Privacy Policy</Link>
            <br/>
            <Link to='/about/terms-of-service'>Terms of Service</Link>
            
            <h1>Social</h1>
            <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'><Icon size='big' name='twitter' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'><Icon size='big' name='instagram' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'><Icon size='big' name='facebook' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.youtube.com/@navscout'><Icon size='big' name='youtube' /></Link>
        </Container>
    )
}

export default About;