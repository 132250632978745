import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import Colors from './Colors';
import Dataset from './Dataset';
import Util from './Util';
import { Search } from 'semantic-ui-react';
import LocationInput from './LocationInput';

//Documentation https://react-google-maps-api-docs.netlify.app/


const REPOLL_AFTER_MOVE_TIME_MS = 250;
const LOAD_BBOX_PADDING = .05;

const GeoMap = props => {
    const API_KEY = process.env.REACT_APP_GMAP_API_KEY;

    const mapItems = useRef([]);
    const pollResult = useRef(null);
    const r = useRef(props.searchValue);

    const onScriptLoad = () => {
        const mapChildren = document.getElementById('map').children.length;
        document.contentCallback = i => {
            if (props.contentCallback) {
                props.contentCallback(i)
            }
        }
        
        if (mapChildren > 0 && window.mapPath === window.location.pathname) {
            return;
        }

        if (window.google) { 
            var mapOptions = {                
                zoom: 4,
                center: new window.google.maps.LatLng(39, -98),
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
                scrollwheel: true,
                maxZoom: 12
            };

            const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
            window.mapPath = window.location.pathname;

            var infoWindow = new window.google.maps.InfoWindow();

            const information = document.getElementById('information');
            const legend = document.getElementById('legend');
            const search = document.getElementById('search');
            map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
            map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(search);
            map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(information);
            map.addListener('tilesloaded', () => setTimeout(() => {
                legend.hidden = false
                search.hidden = false
                information.hidden = true
            }, 50));


            const displayContentInInfoWindow = (item, content) => {
                infoWindow.close()
                infoWindow.setContent(content)
                infoWindow.open({anchor: item, map, shouldFocus: false})
            }

            const clearContentInInfoWindow = () => {
                infoWindow.close()
                infoWindow.setContent(null)
            }

            const displayContentInPanel = (item, content) => {
                information.innerHTML = content
                information.hidden = false;
            }

            const clearContentInPanel = () => {
                information.innerHTML = null;
                information.hidden = true;
            }

            const displayContent = displayContentInPanel;
            const clearContent = clearContentInPanel;

            var moveTime = null;

            const repoll = () =>  {
                var now = new Date().getTime();

                map.addListener('click', e => {
                    if (props.clearContent) {
                        props.clearContent()
                    }
                    clearContent();
                });

                if (moveTime === null || now - moveTime > REPOLL_AFTER_MOVE_TIME_MS) {
                    if (!map.getBounds()) {
                        return
                    }
                    var bounds = map.getBounds().toJSON();

                    let n = bounds.north, e = bounds.east, s = bounds.south, w = bounds.west;

                    const lngDist = Math.abs(e - w);
                    const latDist = Math.abs(n - s);
                    
                    const lngPadding = lngDist * LOAD_BBOX_PADDING;
                    const latPadding = latDist * LOAD_BBOX_PADDING;

                    n = Math.min(89.9, n + latPadding);
                    s = Math.max(-89.9, s - latPadding);
                    
                    if (e > w) {
                        e = Math.min(179.9, e + lngPadding);
                        w = Math.max(-179.9, w - lngPadding);
                    } else {
                        e = Math.max(-179.9, e - lngPadding);
                        w = Math.min(179.9, w + lngPadding);
                    }

                    var bbox = [w, s, e, n];

                    if (Math.abs(w - e) > 350 || Math.abs(n - s) > 175) {
                        bbox = null;
                    }
                    
                    if (props.loadData) {
                        //r.current is the search value
                        props.loadData(r.current?.id, r.current?.label, bbox, (newItems, selected = false) => {
                            if (mapItems.current.length === 0) {
                                const newBounds = getBounds(newItems);
                                const gBounds = new window.google.maps.LatLngBounds();
                                gBounds.extend({lat:newBounds.min.latitude,lng:newBounds.min.longitude});
                                gBounds.extend({lat:newBounds.max.latitude,lng:newBounds.max.longitude});
                                
                                // map.setCenter(gBounds.getCenter());
                                // map.fitBounds(gBounds);
                            }

                            mapItems.current.forEach(i => i.updated = false);
                            var markerDataUpdated = false;
                            
                            newItems.forEach(item => {
                                var oldItem = mapItems.current.filter(i => i?.id === item?.id)[0];
                                if (oldItem) {
                                    if (!oldItem.present) {
                                        console.log("Re-add marker: " + item.id)
                                        oldItem.present = true;
                                        oldItem.items.forEach(item => item.setMap(map));
                                        updateLegend(oldItem);
                                        markerDataUpdated = true;
                                    }
                                    oldItem.updated = true;
                                } else {
                                    console.log("New item: " + item.id)
                                    var content = itemToMapContent(props, map, item, displayContent);
                                    updateLegend(content);
                                    content.present = true;
                                    content.updated = true;
                                    mapItems.current.push(content);
                                    markerDataUpdated = true;
                                }
                            })
                            mapItems.current.forEach(i => {
                                if (i.id && !i.updated && i.present) {
                                    console.log("Remove item: " + i.id);
                                    i.present = false;
                                    i.items.forEach(item => item.setMap(null));
                                    markerDataUpdated = true;
                                }
                            });
                            if (markerDataUpdated && selected && newItems.length === 1) {
                                var coords = newItems[0]?.geometry?.coordinates
                                map.setCenter(new window.google.maps.LatLng(coords[1], coords[0]))
                                map.setZoom(8)
                            }
                        });
                    }
                    moveTime = now;
                }
            };

            const poll = () => {
                if (props.poll) {
                    const current = props.poll();
                    if (current !== pollResult.current) {
                        pollResult.current = current;
                        moveTime = null;
                        repoll();
                    }
                }
                setTimeout(poll, 100);
            }
            poll();

            map.addListener('bounds_changed', repoll)
        }
    }

    useEffect(() => {
        if (!window.gmapsloading) {
            console.log("Load gmaps api")
            window.gmapsloading = true
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.googleapis.com/maps/api/js?key=`+API_KEY+`&libraries=places,geometry`;
            script.id = 'googleMaps';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            script.addEventListener('load', e => {
              console.log("gmaps loaded")
              onScriptLoad()
            })
          } else {
            onScriptLoad()
          }
    });

    return (
        <div id='mapRoot' style={{width:'100%', height:'100%'}}>
            <div id='search' hidden>{props.onSearchChange ? 
                <GeoSearch 
                    client={props.client} 
                    searchValue={props.searchValue?.label} 
                    onSearchChange={l => {
                        r.current = {label: l}
                        props.onSearchChange(l)
                    }} 
                /> : null
            }</div>
            <div id='legend' hidden><h3>Legend</h3><div style={{textAlign:'right',fontWeight:'bold'}} id='legendContent'/></div>
            <div id='information' className='information' hidden><h3>Information</h3></div>
            <div className='map' id='map' style={{width:'100%', height:'100%'}} />
        </div>
    );
}

const GeoSearch = props => {
    return (<LocationInput
        onError={props.onError}
        style={{margin: '1em', fontSize: '1.5em'}}
        placeholder='Place name'
        location={props.searchValue} 
        onChange={props.onSearchChange} 
        client={props.client}
        />)
}

const proportion = (low, high, value) => {
    const ll = numeric(low);
    const lh = numeric(high);
    const lv = numeric(value);
    return (lv - ll) / (lh - ll);
}

const isNumeric = v => {
    if (v === 0) {
        return true;
    }

    if (!v) {
        return false;
    }

    const type = typeof v;

    if (type === 'number') {
        return true;
    } else if (type === 'object') {
        if (v instanceof Date) {
            return true;
        } else if (v instanceof moment) {
            return true;
        }
    }

    return false;
}

const numeric = v => {
    if (!v) {
        return 0;
    }

    const type = typeof v;

    if (type === 'number') {
        return v;
    } else if (type === 'object') {
        if (v instanceof Date) {
            return v.getTime();
        } else if (v instanceof moment) {
            return v.valueOf();
        }
    }

    return 0;
}

const gradientColor = (minValue, maxValue, value) => {
    if (value === maxValue) {
        return '#ff0000';
    }
    
    let scaled = parseInt(proportion(minValue, maxValue, value) * 0xff).toString(16);

    if (scaled.length < 2) {
        scaled = '0' + scaled;
    }

    return `#${scaled}0000`;
}

const updateLegend = item => {
    const l = document.getElementById('legend')
    const lc = document.getElementById('legendContent');

    lc.innerHTML = item.legend || '';

    if (item.legend !== undefined) {
        l.style.display = null;
    } else {
        l.style.display = 'none';
    }
}

const itemToMapContent = (props, map, item, displayContent) => {
    var content = {};

    if (item.geometry.type === "Point") {
        var newItem = new window.google.maps.Marker({
            icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: item.color || Colors.BLUE,
            fillOpacity: 1,
            labelOrigin: {x: 0, y: -2},
            strokeWeight: 1
            },
            position: { lat: item.geometry.coordinates[1], lng: item.geometry.coordinates[0] },
            label: item.label,
            map: map
          });
        newItem.updated = true;
        newItem.id = item.id;
        if (item.content) {
            newItem.addListener('click', () => {
                if (props.renderContent) {
                    displayContent(newItem, ReactDOMServer.renderToString(item.content));
                } else {
                    const contentNode = document.createElement('div');
                    contentNode.innerHTML=item.content;
                    displayContent(newItem, contentNode)
                }
            });
        } else if (props.loadContent) {
            newItem.addListener('click', () => {
                props.loadContent(newItem.id, info => {
                    if (props.renderContent) {
                        displayContent(newItem, ReactDOMServer.renderToString(info));
                    } else {
                        displayContent(newItem, info);
                    }
                })
            });
        }
        content = {id: item.id, items: [newItem]};
    } else if (item.geometry.type === "LineString") {
        const coords = item.geometry.coordinates;
        if (!item.values || item.values.length === 0) {
            content = {
                id: item.id,
                    items: [new window.google.maps.Polyline({
                    path: coords.map(c => {return {lat: c[1], lng: c[0]}}),
                    geodesic: true,
                    map: map
                })]
            }
        } else {
            var lines = [];

            const renderer = generateRenderer(item);

            for (let segment = 0; segment < coords.length - 1; segment ++) {
                lines.push(new window.google.maps.Polyline({
                    path: [{lat: coords[segment][1], lng: coords[segment][0]}, {lat: coords[segment + 1][1], lng: coords[segment + 1][0]}],
                    strokeColor: renderer.colors[segment],
                    geodesic: true,
                    map: map
                }))
            }
            content = {id: item.id, items: lines, legend: renderer.legend};
        }
    } else {
        content = {items:[]};
    }

    return content;
}

const wrap = (min, max, v) => {
    const diff = max - min;
    while (v > max) {
        v -= diff;
    }
    while (v < min) {
        v += diff;
    }

    return v;
}


const generateRenderer = item => {
    const gradient = item.values.every(isNumeric);
    
    if (gradient) {
        const minValue = item.values.reduce((a, b) => Math.min(a, b));
        const maxValue = item.values.reduce((a, b) => Math.max(a, b));

        return {
            legend: `<h4>${Util.print(item?.legend?.unit)}</h4>${minValue.toFixed(1)}&nbsp;<span style="padding-left:12px;background-color:${gradientColor(minValue, maxValue, minValue)}"></span><br/>${maxValue.toFixed(1)}&nbsp;<span style="padding-left: 12px;background-color:${gradientColor(minValue, maxValue, maxValue)}" />`,
            colors: item.values.map(v => gradientColor(minValue, maxValue, v))
        }
    } else {
        const values = [];
        item.values.forEach(v => {
            if (values.indexOf(v) === -1) {
                values.push(v)
            }
        })

        const colors = new Map();

        if ("level" === item.unit) {
            Dataset.sortLevels(values);
            values.reverse();
            Dataset.addLevelColors(values, colors);
        } else {
            values.sort();
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
    
                if (!colors.has(value)) {
                    colors.set(value, Colors.PALLETTE[wrap(0, Colors.PALLETTE.length, colors.size)]);
                }
            }
        }

        let legend = '';


        for (let e of colors.entries()) {
            legend += `${e[0]}<span style="padding-left:12px;background-color:${e[1]}"></span><br/>`;
        }

        return {
            legend,
            colors: item.values.map(v => colors.get(v))
        }
    }
}

const center = (items) => {
    var bounds = getBounds(items);
    if (bounds) {
        return  {
            lat: (bounds.min.latitude + bounds.max.latitude) / 2,
            lng: (bounds.min.longitude + bounds.max.longitude) / 2
        }
    } else {
        return {
            lat: 25.617,
            lng: -80.191
        };
    }
}

const getBounds = (items) => {
    if (!items || items.length === 0) {
        return {min: {latitude: 24.7433195 , longitude: -124.7844079}, max: {latitude: 49.3457868 , longitude: -66.9513812}};
    }

    var minLat = 9999;
    var maxLat = -9999;
    var minLng = 9999;
    var maxLng = -9999;

    if (items && items.length > 0) {
        items.forEach(i => {
            if (i.geometry.type === "Point") {
                var lat = i.geometry.coordinates[1];
                var lng = i.geometry.coordinates[0];
                minLat = Math.min(minLat, lat);
                maxLat = Math.max(maxLat, lat);
                minLng = Math.min(minLng, lng);
                maxLng = Math.max(maxLng, lng);
            } else if (i.geometry.type === "LineString") {
                i.geometry.coordinates.forEach(c => {
                    var lat = c[1];
                    var lng = c[0];
                    minLat = Math.min(minLat, lat);
                    maxLat = Math.max(maxLat, lat);
                    minLng = Math.min(minLng, lng);
                    maxLng = Math.max(maxLng, lng);
                });
            }
        });
    }

    return {min: {latitude: minLat, longitude: minLng}, max: {latitude: maxLat, longitude: maxLng}};
}

export default React.memo(GeoMap);