import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Card, Confirm, Container, Divider, Form, Header, Input, Select, TextArea } from 'semantic-ui-react';
import DateTimeInput from './DateTimeInput';
import LocationInput from './LocationInput';
import TimeUtil from './TimeUtil';
import Validate from './Validate';
import { useNavigate, useParams } from 'react-router-dom';
import ModalText from './ModalText';
import ReportAttachments from './ReportAttachments';
import { useQuery } from '@apollo/client';

const LOAD_REPORT = gql`
query EditReport($id:  String) {
    observation(criteria: {id: $id}) {
        observations {
            id
            start
            end
            modified
            content
            contentType
            title
            category
            type
            reporter
            extent
            uri
            tags
        } 
    }
}`
;

const ATTACHMENTS = gql`
query Attachments($id:  String) {
    observation(criteria: {anchor: $id}) {
        observations {
            id
            title
        }
        countAvailable
    }
}`
;

const ADD_REPORT=gql`
  mutation AddReport($start: Date, $end: Date, $type: ObservationType, $category: String, $title: String, $content: String, $contentType: String, $uri: String, $extent: JSON, $tags: [String]) {
    addObservation(observation: {
      start: $start,
      end: $end,
      title: $title,
      contentType: $contentType,
      content: $content,
      category: $category,
      type: $type,
      extent: $extent,
      uri: $uri,
      tags: $tags
    }) {
      id
    }
  }
`;

const UPDATE_REPORT=gql`
  mutation UpdateReport($id: String!, $start: Date, $end: Date, $type: ObservationType, $category: String, $title: String, $content: String, $contentType: String, $uri: String, $extent: JSON, $tags: [String]) {
    updateObservation(id: $id, observation: {
      start: $start,
      end: $end,
      title: $title,
      contentType: $contentType,
      content: $content,
      category: $category,
      type: $type,
      extent: $extent,
      uri: $uri,
      tags: $tags
    }) {
      id
    }
  }
`;

const DELETE_REPORT=gql`
  mutation DeleteReport($id: String!) {
      deleteObservation(id: $id)
  }
`;

const LOCATION=gql`
  query Location($location: String!) {
    location(input: $location) {
      country
      adminDistrict
      adminDistrict2
      locality
    }
  }
`;

const CONTENT_TYPES = {'Text': 'text/plain', 'Markdown': 'text/markdown'};

const REPORT_TYPES = ['INFORMATION', 'WARNING'];

const CATEGORIES = ['Aviation', 'Conflict', 'Crime', 'Disaster', 'Health', 'Transportation', 'Weather', 'Wildfire'];

const locationFromExtent = e => {
    if (!e || !e.region) return null;

    const r = e.region;

    if (r.locality) { return `${r.locality}, ${r.adminDistrict}, ${r.country}` }
    else if (r.adminDistrict2) { return `${r.adminDistrict2}, ${r.adminDistrict}, ${r.country}` }
    else if (r.adminDistrict) { return `${r.adminDistrict}, ${r.country}` }
    else if (r.country) { return `${r.country}` }
}

const ReportEdit = props => {
    const [start, setStart] = useState(moment().startOf('hour').add(1, 'hour').toDate());
    const [end, setEnd] = useState(moment().startOf('hour').add(2, 'hour').toDate());
    const [title, setTitle] = useState('');
    const [contentType, setContentType] = useState('text/plain');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('INFORMATION');
    const [location, setLocation] = useState('');
    const [uri, setUri] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [tags, setTags] = useState(null);
    const [aiSummarize, setAiSummarize] = useState(false);
    const [aiTitle, setAiTitle] = useState(true);
    const [aiGeoTag, setAiGeoTag] = useState(true);
    const [aiMap, setAiMap] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const [attachmentCount, setAttachmentCount] = useState(0);
    
    console.log("Load report")

    const {loading, error, data} = useQuery(LOAD_REPORT, {
      client: props.client,
      variables: {id: params.id},
      skip: !params.id,
      onCompleted: data => {
        var o = data?.observation?.observations[0];

        if (o) {
          setStart(TimeUtil.toDate(o.start))
          setEnd(TimeUtil.toDate(o.end))
          setType(o.type)
          setTitle(o.title)
          setContentType(o.contentType)
          setContent(o.content)
          setCategory(o.category)
          setLocation(locationFromExtent(o.extent))
          setUri(o.uri)
          setTags(o.tags?.join(", "))
          setAiTitle(!o.title)
          setAiGeoTag(o.extent === null)
        }
      }
    });  

    console.log("Load attachments")

    useQuery(ATTACHMENTS, {
      client: props.client,
      variables: {id: params.id},
      skip: !params.id,
      onCompleted: data => {
        if (data?.observation) {
          setAttachmentCount(data.observation.countAvailable)
        }
      }
    });  
    
    const complete = Validate.notBlank(start, end, content, category, type);

    const getLocation = () => {
        if (location) {
          props.client.query({
              query: LOCATION,
              variables: {location}
          }).then(data => {
              if (data.data.location === null) {
                  console.log('Get location failed')
              } else {
                  submitReport({
                      type: "Region",
                      region: data.data.location
                  });
              }
          })
        } else {
          submitReport()
        }
    }

    const submitReport = extent => {
        const tagList = tags ? tags.split(",").map(tag => tag.trim()) : [];
        if (aiSummarize) {
          tagList.push("@Summarize")
        }
        if (aiTitle) {
          tagList.push("@Title")
        }
        if (aiGeoTag) {
          tagList.push("@GeoTag")
        }
        if (aiMap) {
          tagList.push("@Map")
        }
        
        if (params.id) {
            props.client.mutate({
                mutation: UPDATE_REPORT,
                variables: {id: params.id, start, end, type, category, title, content, contentType, uri, extent, tags: tagList},
                refetchQueries: ['EditReport']
              }).then(data => {
                if (data.data.updateObservation === null) {
                  console.log('Report failed');
                } else {
                  navigate('/report');
                }
              });
        } else {
            props.client.mutate({
                mutation: ADD_REPORT,
                variables: {start, end, type, category, title, content, contentType, uri, extent, tags: tagList},
                refetchQueries: ['EditReport']
              }).then(data => {
                if (data.data.addObservation === null) {
                  console.log('Report failed');
                } else {
                  navigate('/report');
                }
              });
        }
    };

    const deleteReport = () => {
        props.client.mutate({
            mutation: DELETE_REPORT,
            variables: {id: params.id},
            refetchQueries: ['EditReport']
          }).then(data => {
            if (!data?.data?.deleteObservation) {
              console.log('Report delete failed');
            } else {
                console.log(`Deleted report ${params.id}`);
                setConfirmDelete(false);
                navigate('/report');
            }
          });
    };

    console.log("Render")

    return (
        <Container fluid>
          <Confirm
              open={confirmDelete}
              onCancel={() => setConfirmDelete(false)}
              onConfirm={deleteReport}
              />
          
          <Header>Report Information</Header>
          
          <Form>
              <DateTimeInput id='start' 
                  label='Start'
                  placeholder='Time'
                  value={start}
                  onChange={setStart}
                  />
              <DateTimeInput id='end' 
                  label='End'
                  placeholder='Time'
                  value={end}
                  onChange={setEnd}
                  />
              <Form.Field>
                <label>
                  Content&nbsp;
                  (
                  <ModalText text={`Type: ${contentType}`}>
                    <Form.Field>
                      <label>Content Type</label>
                        <Select 
                            options={Object.entries(CONTENT_TYPES).map((k, v) => { return {key: k[1], value: k[1], text: k[0]} })} 
                            value={contentType}
                            onChange={(e,d) => setContentType(d.value)}
                            />
                    </Form.Field>
                  </ModalText>
                  )
                  </label>
                  <TextArea rows='10' placeholder='Report information' value={content} onChange={e => setContent(e.currentTarget.value)} />
              </Form.Field> 
              <Form.Field>
                <label>Attachments</label>
                <ModalText text={`Manage Attachments`}>
                    <ReportAttachments client={props.client} id={params.id} />
                </ModalText>
              </Form.Field>
              <Form.Field>
                  <label>Title (Optional)</label>
                  <input placeholder='Report Title' value={title} onChange={e => {
                      setTitle(e.currentTarget.value)
                      setAiTitle(!Validate.notBlank(e.currentTarget.value))
                    }} />
              </Form.Field>
              <Form.Field>
                  <label>Category</label>
                  <Input list='categories' placeholder='Crime, Weather, ...' value={category} onChange={e => setCategory(e.currentTarget.value)} />
                  <datalist id='categories'>
                      {CATEGORIES.map(c => <option value={c}>{c}</option>)}
                  </datalist>
              </Form.Field>
              <Form.Field>
                  <label>Type</label>
                  <Select 
                      options={REPORT_TYPES.map((t, i) => { return {key: i, value: t, text: t} })} 
                      value={type}
                      onChange={(e,d) => setType(d.value)}
                      />
              </Form.Field>
              <Form.Field>
                  <label>Location (Optional)</label>
                  <LocationInput id='location' 
                      placeholder='Miami, FL'
                      location={location} 
                      onChange={l => {
                        if (l?.label) {
                          setLocation(l.label);
                          setAiGeoTag(!Validate.notBlank(l.label))
                        }
                      }} 
                      client={props.client}
                      />
              </Form.Field>
              <Form.Field>
                <label>Link (Optional)</label>
                <Input id='uri' placeholder='mysite.com' defaultValue={uri} onChange={e => setUri(e.currentTarget.value)} />
              </Form.Field>
              <Form.Field>
                <label>Tags (Optional)</label>
                <Input id='uri' placeholder='Comma separated tags' defaultValue={tags} onChange={e => setTags(e.currentTarget.value)} />
              </Form.Field>
              <div>
                <Form.Checkbox label='Summarize' checked={aiSummarize} onChange={() => setAiSummarize(!aiSummarize)}/>
                <Form.Checkbox label='Title' checked={aiTitle} onChange={() => {setAiTitle(!aiTitle)}}/>
                <Form.Checkbox label='GeoTag' checked={aiGeoTag} onChange={() => setAiGeoTag(!aiGeoTag)}/>
                <Form.Checkbox label='Add Map' checked={aiMap} onChange={() => setAiMap(!aiMap)}/>
              </div>
          </Form>
          <Divider hidden />
          <Button color='black' onClick={() => setConfirmDelete(true)} disabled={!params.id}>
              Delete
          </Button>
          <Button color='black' onClick={() => navigate('/report')}>
              Cancel
          </Button>
          <Button
              content="Submit"
              labelPosition='right'
              icon='checkmark'
              onClick={getLocation}
              positive
              disabled={!complete}
          />
          <Divider hidden />
        </Container>
    );
}

export default ReportEdit;