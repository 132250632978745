import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

const ModalText = props => {
    const [open, setOpen] = useState(false);

    const doSetOpen = value => {
        if (props.setOpen === undefined) {
            setOpen(value)
        } else {
            props.setOpen(value)
        }
    }

    const doGetOpen = () => {
        if (props.open === undefined) {
            return open
        }
        return props.open
    }

    return (
        <span>
            <span className='link' onClick={() => {
                doSetOpen(true)
            }}>{props.text}</span>
            <Modal 
                closeIcon
                onClose={() => doSetOpen(false)}
                open={props.open === undefined ? open : props.open}
                >
                    <Modal.Header>{props.text}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {props.children}
                        </Modal.Description>
                    </Modal.Content>
            </Modal>  
        </span>  
    )
}

export default ModalText;