import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import { first, last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, ButtonOr, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import TimeUtil from './TimeUtil';

const QuantitativeTimeline = props => {
    const chartRef = React.useRef();
    const [day, setDay] = useState(0)
    const firstTime = new Date(props.timeline.times[0])
    const lastTime = new Date(props.timeline.times[props.timeline.times.length - 1])
    const allowPrev = day > 0
    const allowNext = firstTime.getTime() + (day + 1) * (24*60*60*1000) < lastTime

    const startOfDay = TimeUtil.plusDays(TimeUtil.startOfDay(new Date(firstTime)), day)

    useEffect(() => {
        var context = chartRef.current.getContext("2d");

        if (props.timeline) {
            loadChart(context, props.timeline, day);
        }
    })

    return (
        <div style={{textAlign: 'center'}}>
            <canvas id='chart' key={Math.random()} ref={chartRef} />
            <ButtonGroup>
                <Button disabled={!allowPrev} onClick={() => setDay(day - 1)}  icon='angle left' />
                <Button basic color='black' style={{cursor: 'default'}}>{TimeUtil.date(startOfDay)}</Button>
                <Button disabled={!allowNext} onClick={() => setDay(day + 1)}  icon='angle right' />
            </ButtonGroup>
        </div>
    );
}

const loadChart = (context, d, day) => {
    const firstTime = new Date(d.times[0])
    const startOfDay = TimeUtil.plusDays(TimeUtil.startOfDay(new Date(firstTime)), day)
    const endOfDay = TimeUtil.plusDays(startOfDay, 1)
    
    const data = {label: d.label, unit: d.unit, values: [], times: []}

    d.times.forEach((t, i) => {
        const ts = new Date(t).getTime()
        if (ts >= startOfDay && ts < endOfDay) {
                data.times.push(t)
                data.values.push(d.values[i])
        }
    })

    const labels = data.times.map(t => new Date(t))
    const values = data.values

    new Chart(
        context,
        {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: `${data.label} (${data.unit || "no unit"})`,
                        data: values,
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        lineTension: 0
                    }
                ]
            },
            options: {
                animation: false,
                aspectRatio: window.innerWidth < 800 ? 1.5 : 2,
                title: {
                    display: true,
                    text: 'Forecast',
                },
                scales: {
                    x: {
                        type: 'time'
                    },
                    y: {
                        // ticks: {
                        //     min: 0,
                        //     max: 100
                        // }
                    }
                },
                plugins: {
                    tooltip: {
                      callbacks: {
                        label: (item, _data) => {
                            var unit = data.unit
                            if (unit) {
                                return `${item.raw} ${unit}`
                            } else {
                                return item.value;
                            }
                        }
                      }
                    }
                }
            }
        }
    );
}


export default QuantitativeTimeline;