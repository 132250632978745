import React from 'react';
import { Link } from 'react-router-dom';

const Extent = props => printExtent(props.value)

const printExtent = extent => {
    if (extent == null) {
        return null;
    } else if (extent?.region) {
        return [extent.region.locality, extent.region.adminDistrict2, extent.region.adminDistrict, extent.region.country].filter(v => v && v.length).reduce((a, b) => a ? (a + ", " + b) : b, "")
    } else if (extent?.assetType) {
        return <Link to={`/${extent.assetType.toLowerCase()}/${extent.id}`}>A {`${extent.assetType.toLowerCase()}`} asset</Link>
    } else if (extent?.point) {
        return `Latitude: ${extent.point.latitude}, Longitude: ${extent.point.longitude}`
    } else {
        return JSON.stringify(extent);
    }
}

export default Extent;