import gql from 'graphql-tag';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Header, Icon, Placeholder, PlaceholderHeader, PlaceholderLine, PlaceholderParagraph, Segment } from "semantic-ui-react";
import Hideable from './Hideable';
import TimeUtil from './TimeUtil';
import moment from 'moment';
import AdContent from './AdContent';
import AutoContent from './AutoContent';
import { useQuery } from '@apollo/client';

const TRAVEL_BY_ID = gql`
  query TravelById($travelId: String!, $first: Int, $limit: Int) {
      observation(criteria: {subjectId: $travelId, first: $first, limit: $limit, sensor: "com.navscout.main.assess.travel.TravelAssessmentReport"}) {
          countReturned
          countAvailable
          observations {
              id
              modified
              title
              category
              type
              start
              end
              contentType
              content
          } 
      }
      travelInformation(travel: $travelId) {
        id
        created
        label
        departure
        arrival
        status
      }
  }`
    ;

const isLoaded = data => {
    if (typeof data === 'undefined') {
        return false;
    }
    var count = data?.observation?.countReturned
    var loaded = typeof count !== undefined && count > 0
    return loaded
}

const Travel = props => {
    const params = useParams();
    const [reloading, setReloading] = useState(false)
    const countRef = useRef(0);
    const done = useRef(false)

    const { loading, error, data, refetch } = useQuery(
        TRAVEL_BY_ID,
        {
            client: props.client,
            variables: { name: props.name, travelId: params.id, first: 1, limit: 5 },
            skip: (!params.id && !props.name),
            fetchPolicy: 'network-only',
            //Necessary to trigger oncompleted after refetch
            notifyOnNetworkStatusChange: true,
            onCompleted: d => { console.log("onCompleted"); done.current = isLoaded(d); }
        })

    const reload = () => {
        console.log("Reload")
        if (done.current) {
            console.log("Done reloading")
            return
        }
        countRef.current = countRef.current + 1
        refetch()
        setTimeout(reload, 2000)
    }
    
    if (!reloading) { 
        console.log("Start reload");
        setReloading(true);
        reload();
    }

    if (error) {
        return "Error: " + error
    }

    if (!data || (!data.observation && !data.observation)) {
        return <span>You are not subscribed to any travel.</span>;
    }

    return (<div>
        <TravelContent
        data={data}
        client={props.client}
        id={params.id}
        />
        </div>)
}

const parseObservations = obs => {
    obs = obs || { countAvailable: 1, observations: [] };

    obs = ({
        ...obs,
        observations: obs.observations.map(o => ({
            ...o,
            content: (typeof (o?.content) === 'string' && o?.contentType?.endsWith("+json")) ? JSON.parse(o.content) : o.content
        }))
    })

    return obs;
}

const TravelContent = props => {
    const data = props.data;
    const obs = parseObservations(data.observation)
    const contextRef = createRef();
    const MAX_SCAN_TIME_MINUTES = 5;
    const travelAgeMinutes = ageMinutes(data.travelInformation.created);
    const routing = travelAgeMinutes < MAX_SCAN_TIME_MINUTES && obs?.observations?.length === 0;
    const scanning = false;
    const navigate = useNavigate();
    const observation = data?.observation?.observations?.[0];

    document.title = `navscout - ${data?.travelInformation?.label}`;

    return <div ref={contextRef}>
        <h1>
            {data?.travelInformation?.label || props.name || 'Your Trip'}
        </h1>
        <Hideable hidden={!data?.travelInformation?.departure}>
            <h3>{TimeUtil.range(data?.travelInformation?.departure, data?.travelInformation?.arrival)}</h3>
        </Hideable>
        <Hideable hidden={!data?.travelInformation?.id}>
            <Button icon='edit' onClick={() => navigate(`/drive/edit/${data.travelInformation.id}`)} />
            <Divider horizontal />
        </Hideable>

        <AdContent id='ad' />
        <Hideable hidden={!routing && !scanning}>
            <h2>This route is being scanned.</h2>
            <Segment>
                <Placeholder fluid>
                    <PlaceholderHeader>
                        <PlaceholderLine />
                    </PlaceholderHeader>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            </Segment>
        </Hideable>
        <AutoContent
            client={props.client}
            content={observation?.content}
            contentType={observation?.contentType}
        />
        <Hideable hidden={routing || scanning}>
            <a href={`/drive/${props.id}/details`}>Details</a>
        </Hideable>
    </div>
}

const ageMinutes = t => moment().diff(moment(t), 'minutes')

export default Travel;