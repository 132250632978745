import React from 'react';
import { Button } from 'semantic-ui-react';

const RefreshButton = props => (
    <Button 
        icon='refresh'
        className='link'
        title='Update'
        onClick={e => props.client.refetchQueries({include:props.queries})} 
        />
)

export default RefreshButton;