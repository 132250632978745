import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';


const DatasetRisk = props => {
  const chartRef = React.useRef();

  useEffect(() => {
    var context = chartRef.current.getContext("2d");

    if (props.dataset) {
      loadChart(context, props);
    }
  })
  
  return <canvas id='chart' ref={chartRef} />;
}

const loadChart = (context, props) => {
  const dataset = props.dataset;
  const dsLabels = dataset.labels;
  //const units = dataset.units;
  const columns = dataset.data;
  const yAxisLabels = props.yAxisLabels;

  const xCol = columns[dsLabels.indexOf(props.x)];
  const yCol = columns[dsLabels.indexOf(props.y)];
  const labelCol = columns[dsLabels.indexOf(props.label)];

  var data = [];
  var labels = [];
  var yLabels = [];

  for (var row = 0; row < columns[0].length; row++) {
    //data.push({x: xCol?.[row], y: yCol?.[row], label: labelCol?.[row]})
    data.push({x:yCol?.[row],y:yCol?.[row]});
    labels.push(xCol?.[row]);
    yLabels.push(labelCol?.[row])
  }

  new Chart(
      context,
      {
          type: 'bar',
          data: {
              labels: labels,
              datasets: [
                  {
                      label: 'Risk',
                      data: data,
                      backgroundColor: '#ccc',
                      borderColor: '#aaa'
                  }
              ]
          },
          options: {
              aspectRatio: window.innerWidth < 800 ? 1.5 : 2,
              title: {
                  display: true,
                  text: 'Risk',
              },
              scales: {
                  yAxes: [{
                      ticks: {
                          min: 1,
                          max: 8,
                          callback: (value, index, values) => yAxisLabels[value - 1]
                      }
                  }]
              },
              tooltips: {
                callbacks: {
                  label: (item, data) => yLabels[item?.index]
                }
              }
          }
      }
  );
}

export default DatasetRisk;