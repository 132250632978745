import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import SignInRequired from './SignInRequired';
import People from './People';
import Util from './Util';

const RESOURCES = gql`
query Resources {
  account {
    id
    people {
      id
      name
      email
    }
    places {
      id
      label
      location { country label locality adminDistrict }
      listed
    }
  }
}
`
;

const Resources = props => {  
  const {loading, error, data} = useQuery(RESOURCES, {
    client: props.client
  });  
  
  if (!Util.hasSession()) {
    return <SignInRequired />
  }

  if (loading) {
    return 'Loading...';
  }

  if (error) {
    return "Error: " + error;
  }

  document.title = `navscout - Resources`;

  return <div>
      <People client={props.client} data={data} />
    </div>;
}

export default Resources;