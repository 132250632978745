import React from "react"
import { Button, Message, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";

const NotificationDisplay = props => {
    return (
        <Modal
            header='Notifications'
            open={props.notifications?.length > 0}
            content={<p>Notifications</p>}
            >
            <ModalHeader>Notifications</ModalHeader>
            <ModalContent>
                <p>
                    {
                        props.notifications?.map((n, i) => {
                            return <Message key={i} content={n.message} />
                        })
                    }
                </p>
            </ModalContent>
            <ModalActions>
                <Button color='blue' 
                    onClick={() => {
                        props.onClose?.()
                    }}
                    >
                    Dismiss
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default NotificationDisplay;