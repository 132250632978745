import React, { useState } from 'react';
import gql from "graphql-tag";
import { Button, Dropdown, Form, Label } from 'semantic-ui-react';


const ATTACH_BY_ANCHOR=gql`
  mutation Attach($anchor: String, $title: String, $source: String) {
    addObservation(observation: {
      title: $title,
      content: $source,
      remoteContent: true,
      anchor: $anchor,
      anchorRel: IMAGE,
      category: "Image",
      type: INFORMATION,
      status: DRAFT
      uri: $source
    }) {
      id
      title
      contentType
      remoteContent
    }
  }
`;

const ATTACH_BY_SUBJECT=gql`
  mutation Attach($title: String, $source: String, $subjectId: String) {
    addObservation(observation: {
      title: $title,
      content: $source,
      remoteContent: true,
      anchorRel: IMAGE,
      category: "Image",
      type: INFORMATION,
      status: DRAFT
      extent: {
        id: $subjectId,
        assetType: "PLACE"
      }
      uri: $source
    }) {
      id
      title
      contentType
      remoteContent
    }
  }
`;

const UploadMediaForm = props => {
    const [activePane, setActivePane] = useState(0)

    const panes = [
        { menuItem: 'Web', render: () => <WebAttachment onChange={value => setSource(value)} /> },
        { menuItem: 'Upload', render: () => <UploadAttachment /> }
    ]

    const [title, setTitle] = useState("")
    const [source, setSource] = useState("")

    const subjectId = props.subjectId;
    const anchor = props.id

    const attach = () => {
        const attachMode = panes[activePane].menuItem
        
        if (attachMode === "Web") {
            props.client.mutate({
                mutation: subjectId ? ATTACH_BY_SUBJECT : ATTACH_BY_ANCHOR,
                variables: subjectId ? 
                    {source, title, subjectId} : 
                    {anchor: props.id, source, title},
                refetchQueries: ["Attachments", "EditAttachments"]
                }).then(data => {
                if (data.data.addObservation === null) {
                    console.log("Attach failed");
                } else {
                    props.onCompleted?.(data.data.addObservation)
                }
                });
        } else if (attachMode === "Upload") {
            //Upload file
            const file = document.getElementById("attachFile").files[0]
            const data = new FormData()
            data.append("file", file)
            data.append("title", title)
            if (anchor) {
                data.append("anchor", anchor)
            }
            if (subjectId) {
                data.append("subjectId", subjectId)
            }
            fetch(
                process.env.REACT_APP_REST_URI + `/record/upload`,
                {
                    method: "POST",
                    credentials: "include",
                    body: data
                }
            ).then(response => response.json())
            .then(r => {
                props.onCompleted?.(r)
            })
            .catch(error => console.error(error))
        }
    }

    return (
        <Form onSubmit={attach}>
                    <Form.Field>
                        <Label>Title</Label>
                        <Form.Input placeholder='Title' value={title} onChange={e => setTitle(e.target.value)}/>
                    </Form.Field>
                    <Dropdown 
                        placeholder={panes[0].menuItem}
                        defaultValue={panes[0].menuItem}
                        fluid
                        selection
                        options={panes.map(p => {return {key: p.menuItem, text: p.menuItem, value: p.menuItem}})}
                        onChange={(e,d) => setActivePane(panes.map(p => p.menuItem === d.value).indexOf(true))}
                        />
                    
                    {
                        panes[activePane].render()
                    }
                    
                    <Button primary>Attach</Button>
                </Form>
    )
}

const WebAttachment = props => {
    const [source, setSource] = useState("")
    return (
        <Form.Field>
            <Form.Input 
                label='Link'
                placeholder='http://www.host.com/image.jpg' 
                value={source}
                onChange={e => setSource(e.target.value)}
                onBlur={e => props.onChange(source)}
            />
        </Form.Field>
    )
}

const UploadAttachment = props => {
    return (<Form.Field>
            <Form.Input 
                label='File'
                type='file'
                id='attachFile'
                />
        </Form.Field>)
}

export default UploadMediaForm;