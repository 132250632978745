import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

const AboutPrivacy = props => {
    return (
        <div>
            <h1>Privacy Policy</h1>
            <Policy />
        </div>
    )
}

const Policy = () => {
    return (<pre>
        {`
Privacy Policy for navscout.com

Effective Date: October 1, 2024

1. Introduction

Welcome to navscout.com. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our website and services. By accessing or using navscout.com, you consent to the data practices described in this policy.

2. Information We Collect

Personal Information: This can include your name, email address, phone number, postal address, and any other identifiable information you provide.
Usage Information: We collect data on how you interact with our site, including pages visited, links clicked, products viewed, and time spent on the site.
Device Information: Information about your computer or mobile device, including IP address, browser type, operating system, and device identifiers.
Cookies and Other Tracking Technologies: We use cookies and similar technologies to track activity on our site and to hold certain information.

3. How We Use Your Information

Provide and Improve Our Services: To personalize your experience, improve our services, and deliver content tailored to your preferences.
Communications: To send you updates, marketing materials, and respond to your inquiries or requests.
Analytics: To analyze trends, administer the site, track users' movements, and gather demographic information for aggregate use.
Legal Purposes: To comply with laws or respond to lawful requests and legal processes.

4. Disclosure of Your Information

Service Providers: We may share your information with third-party services providers who perform services on our behalf.
Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your data may be transferred as part of that transaction.
Legal Requirements: We may disclose information when required by law or in response to valid legal processes.
Protect Rights: To protect our rights, privacy, safety, or property, and that of our users or the public.

5. Data Security

We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.

6. Your Choices

Opt-Out: You may opt-out of receiving any, or all, communications from us by following the unsubscribe link in emails or by contacting us directly.
Cookie Preferences: You can manage cookie settings through your browser settings, although some features of the site may not function properly without cookies.

7. Children's Privacy

Our service is not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently received personal information from a person under the age of 13, we will delete such information from our records.

8. International Data Transfers

Your information may be transferred to, and processed in, countries other than your own. These countries may have different data protection laws than your home jurisdiction.

9. Retention of Data

We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.

10. Changes to This Privacy Policy

We may update this Privacy Policy to reflect changes to our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically.

11. Contact Information

For any questions or concerns regarding your privacy, you may contact us at privacy@navscout.com (mailto:privacy@navscout.com).

By using navscout.com, you signify your acceptance of this Privacy Policy. If you do not agree with any part of this policy, please do not use our site.
        `}
    </pre>)
}

export default AboutPrivacy;