import React, { useState } from 'react';
import { Button, Checkbox, Confirm, Container, Divider, Form, Input, Radio, Select, Transition } from 'semantic-ui-react';
import DateTimeInput from './DateTimeInput';
import Hideable from './Hideable';
import LocationInput from './LocationInput';
import PersonSearch from './PersonSearch';
import Validate from './Validate';

const toMetric = (value, unit) => {
  if (unit === 'mph') {
    return value * 1.60934
  }

  return value
}

const toImperial = (value, unit) => {
  if (unit === 'kmph') {
    return value / 1.60934
  }

  return value
}

const TravelForm = props => {
    let [origin, setOrigin] = useState(props.origin || '');
    let [dest, setDest] = useState(props.destination || '');
    let [cancelOpen, setCancelOpen] = useState(false);
    let [departTime, setDepartTime] = useState(props.departure || new Date());
    let [leaveNow, setLeaveNow] = useState(!props.departure);
    let [driver, setDriver] = useState(null);
    let [showAdvanced, setShowAdvanced] = useState(props.speedLimit ? true : false);
    let [limitSpeed, setLimitSpeed] = useState(props.speedLimit);
    let [speedLimit, setSpeedLimit] = useState(Math.round(toImperial(props.speedLimit, 'kmph')) || 65);
    let [speedLimitUnit, setSpeedLimitUnit] = useState('mph');

    return <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          if (props.onSubmit) {            
            props.onSubmit(
              props.id,
              origin,
              dest,
              leaveNow ? new Date() : departTime,
              driver,
              setSpeedLimit ? toMetric(speedLimit, speedLimitUnit) : null
            );
          }
        }} >
        <Form.Group widths='equal'>
          <LocationInput id='origin' 
              placeholder='Starting Point'
              location={origin} 
              onChange={l => setOrigin(l?.label)} 
              client={props.client}
              />
          <LocationInput id='dest' 
              placeholder='Destination'
              location={dest} 
              onChange={l => setDest(l?.label)} 
              client={props.client}
              />
        </Form.Group>
        <Hideable hidden={props.id}>
          <Radio toggle
            label='Leave Now'
            checked={leaveNow}
            onChange={e => setLeaveNow(!leaveNow)}
            />
        </Hideable>
        <Transition.Group animation='slide down' duration={100}>
          {!leaveNow && (
            <Container>
              <DateTimeInput id='departTime' 
                  label='Departure Time'
                  placeholder='Time'
                  value={departTime}
                  onChange={setDepartTime}
                  />
            </Container>
          )}
        </Transition.Group>
        <Divider />
        <Radio toggle
            label='Advanced Options'
            checked={showAdvanced}
            onChange={e => setShowAdvanced(!showAdvanced)}
            />
        <Transition.Group animation='slide down' duration={100}>
          {showAdvanced && (
                <Form.Group widths='equal' style={{'marginLeft':'0em'}}>
                  <Divider hidden />
                  <Hideable hidden={props.id}>
                    <PersonSearch 
                        id='driver'
                        placeholder='Driver'
                        onChange={e => setDriver(e.currentTarget.value.id)}
                        client={props.client}
                        />
                  </Hideable>
                  <Divider hidden />
                  <Checkbox toggle label='Limit Speed' checked={limitSpeed} onChange={() => setLimitSpeed(!limitSpeed)} />
                  <Input type='number' value={speedLimit} disabled={!limitSpeed} onChange={(e,d) => setSpeedLimit(d.value) } />
                  <Select 
                    disabled={!limitSpeed}
                    options={[
                      {key: 'mph', value: 'mph', text: 'Miles Per Hour'},
                      {key: 'kmph', value: 'kmph', text: 'Kilometers Per Hour'},
                    ]}
                    value={speedLimitUnit}
                    onChange={(e, d) => setSpeedLimitUnit(d.value)}
                    defaultValue={speedLimitUnit}
                    />
                </Form.Group>
          )}
        </Transition.Group>
        
        <Container textAlign='center'>
          <Button              
              style={{margin:'0 auto 0 auto'}}
              disabled={props.loading || !(Validate.notBlank(origin, dest) && Validate.notSame(origin, dest)) }
              >{props.id ? 'Update' : 'Go'}</Button>
          &nbsp;
          <Confirm 
            open={cancelOpen}
            onCancel={() => setCancelOpen(false)}
            onConfirm={() => {props.onDelete(props.client, props.id);setCancelOpen(false)}}
            trigger={<Button onClick={(e) => {
              e.preventDefault();
              if (props.id) {
                setCancelOpen(true);
              } else {
                props.onDelete(props.client, props.id);
              }
            }}>{props.id ? 'Delete' : 'Cancel'}</Button>} 
            />
        </Container>
</Form>;
}

export default TravelForm;