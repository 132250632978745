import gql from 'graphql-tag';
import React from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import TravelForm from './TravelForm';
import { useQuery } from '@apollo/client';

const ADD_TRAVEL = gql`
mutation AddTravel($departure:Date,$origin:String,$dest:String,$speedLimit:Float) {
	addTravel(input:{departure:$departure, route:[{label:$origin},{label:$dest}],navigationOptions:{speedLimit:$speedLimit}}) {
    id
  }
}`
;

const UPDATE_TRAVEL = gql`
mutation UpdateTravel($id: String!, $departure:Date,$origin:String,$dest:String,$speedLimit:Float) {
	updateTravel(id: $id, input:{departure:$departure, route:[{label:$origin},{label:$dest}],navigationOptions:{speedLimit:$speedLimit}}) {
    id
  }
}`
;

const DELETE_TRAVEL = gql`
mutation DeleteTravel($id: String!) {
  deleteTravel(id:$id)
}
`;

const SET_ASSIGNMENT = gql`
mutation SetAssignment($travel:String!,$person:String!,$task:Task!) {
	setTravelAssignment(travel:$travel,person:$person,task:$task)
}`
;

const GET_TRAVEL = gql`
query GetTravelDetails($id: String!) {
  travelInformation(travel: $id) {
    id
    departure
    arrival
    label
    navigationOptions {
      speedLimit
    }
    assignments {
      task
      person {
        id
        name
      }
    }
    route {
      label
    }
  }
}`;

const TravelEdit = props => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const {loading, error, data} = useQuery(GET_TRAVEL, {client: props.client, variables: {id: params.id}, skip: !params.id})
  const travel = data?.travelInformation;
  const navigate = useNavigate();

  const searchOrigin = searchParams.get('origin');
  const searchDestination = searchParams.get('destination');

  if (loading) {
    return "Loading..."
  }

  if (!props.account) {
    return <Message>
      This page will provide you with a travel report including travel alerts and weather conditions for your trip{searchDestination ? ` to ${searchDestination}` : ''}.
      First you must <Link to='/register'>create an account</Link> or <Link to='/sign-in'>Sign In</Link>.
    </Message>
  }

  return (
    <div>
        <TravelForm 
          onSubmit={(id, origin, dest, departure, driver, speedLimit) => {
            saveTravel(navigate, props.client, id, origin, dest, departure, driver, speedLimit);
          }}
          client={props.client}
          origin={travel?.route[0]?.label || searchOrigin}
          destination={travel?.route[travel?.route?.length - 1]?.label || searchDestination}
          departure={travel?.departure ? new Date(travel.departure) : null}
          speedLimit={travel?.navigationOptions?.speedLimit}
          onDelete={(client, id) => {
            deleteTravel(client, id);  
            navigate('/drive');
          }}
          id={travel?.id}
          />
      </div>);
}

const saveTravel = (navigate, client, id, origin, dest, departure, driver, speedLimit) => {
  client.mutate({
    mutation: id ? UPDATE_TRAVEL : ADD_TRAVEL,
    variables: {id, origin, dest, departure, speedLimit},
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (data.data.addTravel === null) {
      console.log('Add travel failed');
    } else {
      console.log('Updated travel')
      if (driver) {
        setAssignment(client, data.data.addTravel.id, driver, 'DRIVER', () => {});
      } else {
        //close
      }
      navigate(`/drive/${data.data.addTravel.id}`)
    }
  });
}

const setAssignment = (client, travel, person, task, then) => {
  client.mutate({
    mutation: SET_ASSIGNMENT,
    variables: {travel, person, task},
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (data.data.setTravelAssignment === null) {
      console.log('Set assignment failed');
    } else {
      then();
    }
  });
}

const deleteTravel = (client, id) => {
  console.log('Delete travel: ' + id);
  
  client.mutate({
    mutation: DELETE_TRAVEL,
    variables: {id},
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (!data.data.deleteTravel) {
      console.log('Delete travel failed');
    }
    console.log('Deleted travel')
  });
}

export default TravelEdit;