class Colors {
    static BLUE = '#7777ff';
    static GREEN = '#77ff77';
    static RED = '#ff7777';
    static BLACK = '#000000';
    static WHITE = '#ffffff';

    static PALLETTE = ['BlueViolet', 'Coral', 'Cornsilk', 'DarkGreen', 'DarkSlateGray', 'FireBrick', 'LightBlue', 'Maroon', 'Orange', 'SaddleBrown'];
}

export default Colors;