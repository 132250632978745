import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Divider, Form, Message } from 'semantic-ui-react';
import Validate from './Validate';
import Hideable from './Hideable';
import SignInThirdParty from './SignInThirdParty';

const REGISTER = gql`
mutation Register($name:String, $email:String!, $password:String!) {
  register(input: {name:$name, email:$email}, password: $password) {
    created
  }
}`
;

const Register = props => {
  const [values, setValues] = useState({name: '', email: '', password: ''});
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const handleChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  document.title = `navscout - Register`;

  return <Container>
      <h3>Let's get started.</h3>
      In order to monitor your travel, you will need to create a free account.<br/>
      Enter your email address and a password so that you will be able to sign in.<br/>
      You can optionally provide your name so that emails will be addressed to you.
      <SignInThirdParty account={props.account} client = {props.client} />
      <Divider horizontal />
      <Hideable hidden={error === null}>
        <Message error>Unable to register with the provided account details</Message>
      </Hideable>
      <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          register(props.client, values, navigate, setError);
        }} >
        <Form.Field>
          <label>Email</label>
          <input name='email' placeholder='Email' value={values.email} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input type='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Name</label>
          <input name='name' placeholder='Name (Optional)' value={values.name} onChange={handleChange} />
        </Form.Field>
        <Container textAlign='center'>
          <Button
              disabled={!Validate.notBlank(values.email, values.password)}
              style={{margin:'0 auto 0 auto'}}
              color='blue'
              >Register</Button>
        </Container>
      </Form>
    </Container>;
}

const register = (client, values, navigate, setError) => {
  console.log('register ', values);
  client.mutate({
    mutation: REGISTER,
    variables: values,
    refetchQueries: ['Account']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Registration failed');
    } else {
      navigate('/');
    }
  }).catch(e => {
    console.error("Unable to register", e)
    setError(e)
  });
}

export default Register;