import React, { useState } from 'react';
import UploadMediaForm from './UploadMediaForm';
import { useNavigate } from 'react-router-dom';

const UploadMedia = props => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const subjectId = searchParams.get('subjectId');

return (<div>
        <h1>Upload {subjectId}</h1>
        <UploadMediaForm
                subjectId={subjectId}
                client={props.client}
                onCompleted={a => {
                    console.log("Done!")
                    console.log(a)
                    navigate(`/gallery?subjectId=${subjectId}`)
                }}
                />
    </div>)
}

export default UploadMedia;