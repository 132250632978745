import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ObservationContent from './ObservationContent';
import SubjectContext from './SubjectContext';
import { Divider } from 'semantic-ui-react';

const REPORT = gql`
query Report($id: String) {
    observation(criteria: {id: $id}) {
        observations {
            id
            start
            end
            created
            modified
            affectedAssets {
                id
                label
                type
            }
            content
            contentType
            title
            category
            type
            reporter
            extent
            uri
            tags
            subjects {
                id
                type
                label
            }
        } 
    }
}`
;


const Report = props => {
    const [report, setReport] = useState(null);
    const params = useParams();
    const {loading, error, data} = useQuery(REPORT, {
        client: props.client, 
        variables: {
            id: params.id
        },
        onCompleted: d => setReport(d?.observation?.observations[0])
    });

    if (loading) {
        return "Loading...";
    }

    if (error) {
        return "Error: " + error;
    }

    const observation = data?.observation.observations[0];

    document.title = `navscout - ${observation?.title}`;

    return (
        <div>
            <h1>
                {observation?.title}                
            </h1>
            <ObservationContent observation={report} client={props.client} account={props.account} />
            <SubjectContext subject={{type: 'RECORD', id: observation?.id, label: observation?.title}} />
            <Divider horizontal />
        </div>
    )
}

export default Report;