class Validate {
    static notBlank = (...values) => {
        if (!values) {
            return true;
        }
        
        return values.filter(v => v === null || (typeof v === 'undefined') || v === '').length === 0
    }

    static notSame = (a, b) => {
        return a !== b
    }
}

export default Validate;