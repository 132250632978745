import gql from "graphql-tag";

const records = gql`
query Records($first: Int, $limit: Int, $type: ObservationType, $reporter: String, $query: String, $modified:String, $occurring:String, $category: String, $tags: [String], $location: String, $subjectId: String, $anchorRel: String, $contentType: String, $fetchContent: Boolean!) {
    observation(criteria: {first: $first, limit: $limit, type: $type, reporter: $reporter, query: $query, modified: $modified, occurring: $occurring, category: $category, tags: $tags, location: $location, subjectId: $subjectId, anchorRel: $anchorRel, contentType: $contentType}) {
        countReturned
        countAvailable
        observations {
            anchor
            anchorRel
            id
            category
            title
            start
            end
            modified
            content @include(if: $fetchContent)
            contentType
            type
            reporter
            extent
        } 
    }
}`
;

class NavscoutClient {
    static records(client, filters, cb) {
        client.query({
            query: records, 
            variables: {
                fetchContent: true,
                ...filters
            },
          }).then(d => {
            cb(d.data.observation.observations)
          }).catch(e => {
            console.error(e)
          });
    }
}

export default NavscoutClient;