import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import Dataset from './Dataset';
import TimeUtil from './TimeUtil';
import { Button, ButtonGroup, Grid, GridColumn, GridRow } from 'semantic-ui-react';

const QualitativeTimeline = props => {
    const chartRef = React.useRef();
    const [day, setDay] = useState(0)
    const firstTime = new Date(props.timeline.times[0])
    const lastTime = new Date(props.timeline.times[props.timeline.times.length - 1])
    const allowPrev = day > 0
    const allowNext = firstTime.getTime() + (day + 1) * (24*60*60*1000) < lastTime

    const startOfDay = TimeUtil.plusDays(TimeUtil.startOfDay(new Date(firstTime)), day)

    useEffect(() => {
      var context = chartRef.current.getContext("2d");

      if (props.timeline) {
        loadChart(context, props.timeline, day);
      }
  })
    
  return (
    <div style={{textAlign: 'center'}}>
        <canvas id='chart' key={Math.random()} ref={chartRef} />
        <ButtonGroup>
            <Button disabled={!allowPrev} onClick={() => setDay(day - 1)}  icon='angle left' />
            <Button basic color='black' style={{cursor: 'default'}}>{TimeUtil.date(startOfDay)}</Button>
            <Button disabled={!allowNext} onClick={() => setDay(day + 1)}  icon='angle right' />
        </ButtonGroup>
    </div>
);
}

const loadChart = (context, d, day) => {
  const firstTime = new Date(d.times[0])
  const startOfDay = TimeUtil.plusDays(TimeUtil.startOfDay(new Date(firstTime)), day)
  const endOfDay = TimeUtil.plusDays(startOfDay, 1)
  
  const data = {label: d.label, unit: d.unit, values: [], times: []}

  d.times.forEach((t, i) => {
      const ts = new Date(t).getTime()
      if (ts >= startOfDay && ts < endOfDay) {
              data.times.push(t)
              data.values.push(d.values[i])
      }
  })

  const {times, values} = dedup(data.times, data.values.map(v => v === null ? '' : v));
  const labels = times.map(t => new Date(t))
  var yLabels = new Set();
  values.forEach(v => yLabels.add(v));
  yLabels=[...yLabels];

  Dataset.sortLevels(yLabels);

  new Chart(
      context,
      {
          type: 'line',
          data: {
              xLabels: labels,
              yLabels: yLabels,
              datasets: [
                  {
                      label: data.label,
                      data: values,
                      backgroundColor: '#ccc',
                      borderColor: '#aaa',
                      lineTension: 0
                  }
              ]
          },
          options: {
            animation: false,
              aspectRatio: window.innerWidth < 800 ? 1.5 : 2,
              title: {
                  display: true,
                  text: 'Forecast',
              },
              scales: {
                  x: {
                      type: 'time'
                  },
                  y: {
                      type: 'category'
                  }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (item, _data) => {
                        return item.raw;
                    }
                  }
                }
              }
          }
      }
  );
}

const dedup = (times, values) => {
  if (!times || times.length < 3) {
    return {times, values};
  }

  var lastValue = values[0];
  var lastTime = times[0];
  var deduped = {times: [times[0]], values: [values[0]]};

  for (var i = 1; i < values.length - 1; i++) {
    if (values[i] !== lastValue) {
      deduped.times.push(lastTime);
      deduped.values.push(lastValue);
      deduped.times.push(times[i]);
      deduped.values.push(values[i]);
      lastValue = values[i];
    }
    lastTime = times[i];
  }

  deduped.times.push(times[times.length - 1]);
  deduped.values.push(values[values.length - 1]);

  return deduped;
}

export default QualitativeTimeline;