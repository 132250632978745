import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Container, Dropdown, Form, Icon, Label } from 'semantic-ui-react';
import ModalText from './ModalText';
import UploadMediaForm from './UploadMediaForm';

const ATTACHMENTS = gql`
query EditAttachments($id:  String) {
    observation(criteria: {anchor: $id}) {
        observations {
            id
            title
            contentType
            remoteContent
        }
        countAvailable
    }
}`
;

const DELETE=gql`
    mutation Delete($id: String!) {
        deleteObservation(id:$id)
    }
`;

const ReportAttachments = props => {
    const [attachments, setAttachments] = useState([])

    useQuery(ATTACHMENTS, {
        client: props.client,
        variables: {id: props.id},
        skip: !props.id,
        onCompleted: data => {
          console.log("Fetch Attachments", data?.observation)
          if (data?.observation) {
            setAttachments(data.observation.observations)
          }
        }
      });  

    return (
        <Container>
            <ul>
                {attachments.map((r, i) => {
                    return (<li key={i}>
                        {r.title} ({r.contentType}) {r.id}
                        <a href={process.env.REACT_APP_REST_URI + `/record/${r?.id}/content`} target='__blank'><Icon name='file outline' /></a>
                        <Icon 
                            className='link' 
                            style={{textDecoration: 'none'}} 
                            name='delete' 
                            onClick={e => 
                                props.client.mutate({
                                    mutation: DELETE,
                                    variables: {id: r.id},
                                    refetchQueries: ['Attachments', 'EditAttachments']
                                  }).then(data => {
                                    if (data.data.addObservation === null) {
                                      console.log('Attach failed');
                                    } else {
                                        setAttachments(attachments.filter(a => a.id !== r.id))
                                    }
                                  })
                            }
                            />
                    </li>)
                })}
            </ul>
            
            <AttachForm 
                client={props.client} 
                id={props.id}
                attachments={attachments}
                setAttachments={setAttachments}
                />
        
        </Container>
    )
}

const AttachForm = props => {
    const [open, setOpen] = useState(false)
   
    return (<ModalText text='Attach' open={open} setOpen={setOpen}>
            <UploadMediaForm   
                client={props.client}
                onCompleted={a => {
                    props.setAttachments([a].concat(props.attachments))
                    setOpen(false)
                }}
                />
            </ModalText>);
}

export default ReportAttachments;