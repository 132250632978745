import React from 'react';
import { Message } from 'semantic-ui-react';


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: null };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { error: error, hasError: true };
    }
  
    componentDidCatch(error, info) {
      // Example "componentStack":
      //   in ComponentThatThrows (created by App)
      //   in ErrorBoundary (created by App)
      //   in div (created by App)
      //   in App
    //   logErrorToMyService(error, info.componentStack);
    }
  
    render() {
      if (this.state.hasError) {
        if (this.props.fallback) {
            return this.props.fallback;
        }
        console.log(this.state.error?.toString())
        return <Message error>There was an error: {this.state.error?.toString()}</Message>
      }
  
      return this.props.children;
    }
  }

  export default ErrorBoundary;