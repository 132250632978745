import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Button, Container, Dropdown, Form, Icon, Modal } from 'semantic-ui-react';
import PersonSearch from './PersonSearch';

const TRAVEL_PEOPLE = gql`
query TravelPeople($travel:String!) {
    travelInformation(travel:$travel) {
      id
      assignments {
        task
        person {
          id
          name
        }
      }
    }
  }`
;

const SET_ASSIGNMENT = gql`
mutation SetAssignment($travel:String!,$person:String!,$task:Task!) {
	setTravelAssignment(travel:$travel,person:$person,task:$task)
}`
;

const DELETE_ASSIGNMENT = gql`
mutation DeleteAssignment($travel:String!,$person:String!) {
	deleteTravelAssignment(travel:$travel,person:$person)
}`
;

const TravelPeople = props => {
  const [open, setOpen] = useState(false);
  const [person, setPerson] = useState(null)
  const [task, setTask] = useState('DRIVER');

  const {loading, error, data} = useQuery(TRAVEL_PEOPLE, {client: props.client, variables: {travel: props.travel}, skip:!open});

  return <Modal
          trigger={<a href='#' onClick={() => setOpen(true)}><Icon name='user' />{props.text??'People'}</a>}
          open={open}
          onClose={() => setOpen(false)}
          closeIcon
      >
      <Modal.Header>People</Modal.Header>
      <Modal.Content>
        {data?.travelInformation?.assignments?.map((a, i) => {
          return <Container key={i}>
            {a.person.name} ({a.task}) <Icon name='delete' className='link' onClick={e => deleteAssignment(props.client, props.travel, a.person.id) } />
          </Container>
        })}
        <Form onSubmit={e => {e.preventDefault(); setAssignment(props.client, props.travel, person, task, () => {setPerson(null); setTask('DRIVER')}); }}>
          <Form.Group inline>
            <PersonSearch 
                id='driver'
                placeholder='Driver'
                onChange={e => setPerson(e.currentTarget.value.id)}
                client={props.client}
                />
            <Dropdown 
              fluid
              selection
              options={[
                {key: 'DRIVER', text: 'Driver', value: 'DRIVER'},
                {key: 'PASSENGER', text: 'Passenger', value: 'PASSENGER'}
              ]}
              value={task}
              onChange={(e, d) => setTask(d.value)}
              />
              &nbsp;
            <Button>{props.action}</Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>;
}

const setAssignment = (client, travel, person, task, then) => {
  console.log('set assignment ' + travel + ' ' + person + ' ' + task);
  client.mutate({
    mutation: SET_ASSIGNMENT,
    variables: {travel, person, task},
    refetchQueries: ['TravelInformation', 'TravelPeople']
  }).then(data => {
    if (data.data.setTravelAssignment === null) {
      console.log('Set assignment failed');
    } else {
      then();
    }
  });
}

const deleteAssignment = (client, travel, person) => {
  console.log('delete assignment ' + travel + ' ' + person);
  client.mutate({
    mutation: DELETE_ASSIGNMENT,
    variables: {travel, person},
    refetchQueries: ['TravelInformation', 'TravelPeople']
  }).then(data => {
    if (data.data.deleteTravelAssignment === null) {
      console.log('Delete assignment failed');
    }
  });
}

export default TravelPeople;