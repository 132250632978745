import React from 'react';
import Assistant from './Assistant';
import { useParams, useSearchParams } from 'react-router-dom';

const Chat = props => {
  document.title = `navscout`;
  // const subjectType = props.subjectType;
  // const { subjectId } = useParams()
  const [searchParams] = useSearchParams();
 
  let subjectId = searchParams.get('subject')
  let subjectType = null
  let drive = searchParams.get('drive')
  let place = searchParams.get('place')
  let record = searchParams.get('record')

  if (drive) {
    subjectId = drive;
    subjectType = 'DRIVE'
  } else if (place) {
    subjectId = place
    subjectType = 'PLACE'
  } else if (record) {
    subjectId = record
    subjectType = 'RECORD'
  }
  
  const subject = (subjectType && subjectId) ? {id: subjectId, type: subjectType} : null;

  return (
    <Assistant subject={subject} client={props.client} open={true} account={props.account}  />
  )
}


export default Chat;