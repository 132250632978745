import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { Container, FormField, Grid, Icon, Search } from 'semantic-ui-react';
import Util from './Util';

const FIND_PLACE = gql`
query FindPlace($label: String!) {
  place(criteria: {label:$label}) {
    places {
      id
      label
      location { label locality adminDistrict countryCode }
    }
  }
  autocomplete(input:$label) {
    label
  }
}
`;

const GEOLOCATE = gql`
query {
  geolocate {
    id
    label
  }
}
`;

const place = label => {
  return {label, type: 'PLACE'}
}

/**
 * 
 * @param {*} props
 * @param {string} props.location The text location name, ie "Boston, MA"
 * @returns 
 */
const LocationInput = (props) => {
    //The text that has been entered
    const [enteredText, setEnteredText] = useState(props.location||"")
    //Are search results loading?
    const [loading, setLoading] = useState(false);
    //Results from search
    const [results, setResults] = useState([]);
    /*
    The selected place: {
      id: place id (optional, present if source is navscout)
      label: place label
      source: navscout | google
    }
    */
    const [selectedPlace, setSelectedPlace] = useState(null)
    const [open,setOpen] = useState(false);
    return (
          <FormField>
            <label>{props.label}</label>
            <Search
                    style={props.style}
                    //Are places being searched for?
                    loading={loading}
                    //Called when a search result is selected
                    onResultSelect={(e, {result}) => {
                        setResults([]);
                        setOpen(false)
                        setSelectedPlace(result)
                        setEnteredText(result.label)
                        if (props.onChange) {
                            props.onChange(result);
                        }
                    }}
                    //Called when text is typed into input
                    onSearchChange={e=> {
                      var newValue = e.currentTarget.value;
                      setEnteredText(newValue)
                      //If at least 3 characters have been entered, search
                      if (newValue?.length >= 3) {
                        setOpen(true);
                        _.debounce(() => search(props.client, props.onlyKnown, newValue, setLoading, setResults), 250, {leading:true}, props.onError)()
                      } else {
                        setOpen(false)
                        setResults([])
                      }
                    }}
                    resultRenderer={p => {
                        if (p.source === 'navscout') {
                          return <Grid style={{paddingRight:'1em'}}>
                              <Grid.Row>
                                <Grid.Column width={9}>
                                {p.label}
                                </Grid.Column>
                                <Grid.Column width={1} floated='right'>
                                  <Icon name='star' />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                        }
                        if (p.last && p.source === 'google') {
                            return <Grid padded>
                                    <Grid.Row>{p.label}</Grid.Row>
                                    <Grid.Row centered><img src='/image/powered_by_google_on_white.png' width={144} height={18} alt='Powered by Google' /></Grid.Row>
                                </Grid>
                        }
                        return <Container>{p.label}</Container>
                    }}
                    results={results.slice(0, 10)}
                    value={enteredText}
                    placeholder={props.placeholder}
                    open={open}
                    autoComplete="off"
                    list="autocompleteoff"
                    onClick={e => {
                      if (e.nativeEvent.target.className === 'prompt') {
                        // search(props.client, props.onlyKnown, '', setLoading, setResults);
                        // setOpen(true)
                      };
                      }
                    }
                    onFocus={e => {
                      // search(props.client, props.onlyKnown, '', setLoading, setResults);
                      // setOpen(true)
                    }}
                    onBlur={e => {
                      setOpen(false);
                      if (Util.isBlank(enteredText) && props.onChange) {
                        props.onChange(null);
                      }
                    }}
                    attached='left'
                    icon={<Icon 
                      name='crosshairs'
                      link
                      onClick={e => geolocate(props.client, props.onError, placeData => {
                          //TODO Set location source? (navscout/google)
                          //TODO also load id, id can determine if navscout/google sourced
                          //Set source: id ? 'navscout' : 'google', id
                          let p = {
                            label: placeData.label,
                            id: placeData.id,
                            source: placeData.id === null ? 'google' : 'navscout'
                          }

                          setSelectedPlace(p)
                          setEnteredText(p.label)
                          
                          if (props.onChange) {
                            props.onChange(p);
                          }
                        }
                      )}
                      />}
                    />
            </FormField>
          )
}

/**
 * 
 * @param {*} client client to make call
 * @param {*} cb callback, passes the place data
 */
const geolocate = (client, onError, cb) => {
  if (!client) {
    onError({message:"Client not provided"})
  }
  client.query({
    query: GEOLOCATE
  }).then(data => {
    if (data.data.geolocate === null || data.data.geolocate.label === null) {
      onError({message:'Unable to determine location'});
    } else {
      cb(data.data.geolocate);
    }
  })
}

const search = (client, onlyKnown, v, setLoading, setResults, onError) => {
    if (!Util.isDefined(v) || v === "") {
      setResults([])
      setLoading(false)
      return
    }
    
    setLoading(true);

    client.query({
        query: FIND_PLACE,
        variables: { label: v }
      }).then(data => {
        var results = [];
        var places = data?.data?.place?.places;
        var location = data?.data?.autocomplete;

        if (places) {
          places.map(p => {
            return {
              label: p.label,
              title: p.label,
              id: p.id,
              value: `${p.location.locality}, ${p.location.adminDistrict} ${p.location.countryCode}`,
              source: 'navscout',
              last: 0
          }}).forEach(p => results.push(p));
        }

        if (location) {
          location.map(p => {return {
            label: p.label,
            title: p.label,
            value: p.label,
            source: 'google',
            last: 0
          }}).forEach(p => {
            //do not add a duplicate place
            if (results.filter(r => r.label === p.label).length > 0) {
              return
            }
            results.push(p)
          })
        }

        if (onlyKnown) {
          results = results.filter(r => r.id);
        }

        if (results && results.length > 0) results[results.length - 1].last = 1;

        setResults(results);
        setLoading(false);
      }).catch(err => {
        onError(err);
      })
}


export default LocationInput;