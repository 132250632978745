import moment from 'moment';

class TimeUtil {
    static MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    static startOfDay(d) {
        const startOfDay = new Date(d)
        startOfDay.setHours(0)
        startOfDay.setMinutes(0)
        startOfDay.setSeconds(0)
        startOfDay.setMilliseconds(0)
        return startOfDay
    }

    static plusDays(d, offset) {
        return new Date(d.getTime() + (offset * 24 * 60 * 60 * 1000))
    }

    static shortTime(t) {
        if (t === null) {
            return null
        }
        var today = moment().startOf('day');
        var mt = moment(t);

        if (!mt.isBefore(today) && mt.isBefore(today.endOf('day'))) {
            return mt.format('h:mm A')
        }

        return mt.format('MMM D')
    }

    static longTime(t) {
        if (!t) {
            return "";
        }
        
        var mt = moment(t);

        return mt.format('MMM D h:mm A')
    }

    static range(start, end) {
        start = TimeUtil.toDate(start)
        end = TimeUtil.toDate(end)
        var s_date = TimeUtil.date(start);
        var e_date = TimeUtil.date(end);
        var s_time = TimeUtil.time(start);
        var e_time = TimeUtil.time(end);

        if (!e_date) {
            return s_date;
        }

        if (s_date === e_date) {
            if (s_time === e_time) {
                return s_date + ' ' + s_time;
            } else {
                return s_date + ' ' + s_time + ' - ' + e_time;
            }
        } else {
            return s_date + ' - ' + e_date;
        }
    }

    static toDate(d) {
        if (d === null) {
            return null;
        }
        if (typeof d === "string") {
            return new Date(d);
        }
        return d;
    }

    static dateTime(date) {
        var dv = TimeUtil.date(date);
        var tv = TimeUtil.time(date);
        return dv ? dv + ' ' + tv : tv;
    }

    static date(date) {
        if (date == null) {
            return null;
        }
        var now = new Date();
        var y = date.getFullYear();
        var m = TimeUtil.MONTHS[date.getMonth()];
        var d = date.getDate();
        var thisYear = now.getFullYear() === y;

        var dt = m + ' ' + d;

        if (!thisYear) {
            dt = dt + ' ' + y;
        }

        return dt;
    }
      
    static time(date) {
        if (date == null) {
            return null;
        }
        var h = date.getHours();
        var m = date.getMinutes();
        var pm = false;
        if (h === 0) { h = 12 }
        if (h > 11) { pm = true }
        if (h > 12) { h -= 12 }

        var t = '' + h;

        if (m !== 0) {
            t = t + ':' + (m < 10 ? '0' : '') + m;
        }

        t = t + ' ' + (pm ? 'PM' : 'AM');

        return t;
    }
}

export default TimeUtil;
