import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import { gql, useQuery } from '@apollo/client';
import { Button,  Divider,  Image } from 'semantic-ui-react';
import RefreshButton from './RefreshButton';

const STORIES = gql`
query CarouselStories($first: Int) {
  observation(criteria: {limit:1, first: $first, contentType:"image/*", anchorRel: "CARD"}) {
    observations {
      id
      title
      anchor
    }
    countReturned
    countAvailable
  }
}
`;

const CardCarousel = props => {
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(1);

  const {data} = useQuery(
    STORIES,
     {
      client: props.client,
      variables: {first},
      onCompleted: d => {
        setLast(d.observation.countAvailable)
    }
    }
  );

  return (<div style={{textAlign: 'center'}}>
        {data?data?.observation?.observations?.map(o => (
            <Image 
              size='big'
              src={`${process.env.REACT_APP_REST_URI}/record/${o.id}/content`} 
              alt={`${o.title}`}
              href={`/report/${o.anchor}`}
              style={{}}
              />
            )
        ) : null}
        <Divider horizontal />
        <Button icon="arrow alternate circle left outline" 
          disabled={first === 1} 
          onClick={e => setFirst(first - 1)}
          style={{marginRight: '1em'}}
          title='Last'
          />
        <Button icon="arrow alternate circle right outline" 
          disabled={first === last} 
          onClick={e => setFirst(first + 1)}
          style={{marginRight: '1em'}}
          title='Next'
          />
          <RefreshButton client={props.client} queries={['CardCarousel']} />
      </div>)
}

export default CardCarousel;