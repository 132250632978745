import moment from "moment";
import Util from "./Util";

const LEVEL_ORDER = ["VERY LOW", "LOW", "MEDIUM", "MODERATE", "HIGH", "VERY HIGH"];

class Dataset {
    static sortLevels(l) {
        l.sort((a, b) => {
            if (a === null) {
                return 1
            }
            if (b === null) {
                return -1
            }
            var ai = LEVEL_ORDER.indexOf(a.toUpperCase());
            var bi = LEVEL_ORDER.indexOf(b.toUpperCase());
            
            return ai > bi ? -1 : (ai < bi ? 1 : 0);
        })
    }

    static addLevelColors(values, colors) {
        var i = 0;
        while (i < values.length) {
            colors.set(values[i], Dataset.gradientColor(0, values.length - 1, i));
            i++;
        }
    }

    static gradientColor(minValue, maxValue, value) {
        if (value === maxValue) {
            return '#ff0000';
        }

        let scaled = parseInt(Dataset.proportion(minValue, maxValue, value) * 0xff).toString(16);
    
        if (scaled.length < 2) {
            scaled = '0' + scaled;
        }
    
        return `#${scaled}0000`;
    }

    static numeric(v) {
        if (!v) {
            return 0;
        }
    
        const type = typeof v;
    
        if (type === 'number') {
            return v;
        } else if (type === 'object') {
            if (v instanceof Date) {
                return v.getTime();
            } else if (v instanceof moment) {
                return v.valueOf();
            }
        }
    
        return 0;
    }

    static renderer(ds) {
        if (!ds || !ds.data) {
            return 'EMPTY';
        }

        if (ds?.units[0] === 'timestamp') {
            return 'TIME_MULTI';
        }

        if (!ds.labels) {
            return 'TABLE';
        }

        if (Util.containsAll(ds.labels, ['Factor', 'Risk', 'Order'])) {
            return 'RISK';
        }

        if (Util.containsAll(ds.labels, ['Latitude', 'Longitude'])) {
            return 'MAP';
        }

        if (ds.data.length === 1 && ds.data[0].length === 1) {
            return 'VALUE';
        }
        
        if (ds.data.length > 0 && ds.data[0].length === 1) {
            return 'MULTI_VALUE';
        }

        return 'TABLE';
    }

    static compare = (a, b) => a < b ? -1 : a > b ? 1 : 0;

    static isNumeric(v) {
        if (!v) {
            return false;
        }
    
        const type = typeof v;
    
        if (type === 'number') {
            return true;
        } else if (type === 'object') {
            if (v instanceof Date) {
                return true;
            } else if (v instanceof moment) {
                return true;
            }
        }
    
        return false;
    }

    static proportion(low, high, value) {
        const ll = Dataset.numeric(low);
        const lh = Dataset.numeric(high);
        const lv = Dataset.numeric(value);
        return (lv - ll) / (lh - ll);
    }

    static proportional(low, high, p) {
        var offset =  (high - low) * p;

        return low + offset;
    }
    
    static getInterpolatedValue(ds, colIndex, rowIndex, p) {
        var result = ds[colIndex][rowIndex];
    
        if (Dataset.isNumeric(result)) {
            var nextResult = ds[colIndex][rowIndex + 1];
            return Dataset.proportional(result, nextResult, p);
        } else {
            //If non-numeric, do not interpolate
            return result;
        }
    }
    
    static lookupWithInterpolate(ds, searchColIndex, searchValue, resultColIndex) {
        const searchCol = ds[searchColIndex];

        if (!searchCol) {
            return null;
        }
    
        for (let row = 0; row < searchCol.length; row++) {
            const value = searchCol[row];
            const valueCmp = Dataset.compare(searchValue, value);
    
            if (valueCmp === 0) {
                //Exact match, no interpolation necessary
                return ds[resultColIndex][row];
            } else if (valueCmp < 0) {
                if (row === 0) {
                    //Prior to low value, return first entry in result column
                    return ds[resultColIndex][row];
                } else {
                    throw 'Search column is not sorted';
                }
            } else if (row < searchCol.length - 1) {
                //If not at end, see if searchValue is between current row and next
                var nextValue = searchCol[row + 1];
                var nextCmp = Dataset.compare(searchValue, nextValue);
    
                if (nextCmp === -1) {
                    //Value is between this row and nextValue, interpolate
                    var p = Dataset.proportion(value, nextValue, searchValue);
                    
                    return Dataset.getInterpolatedValue(ds, resultColIndex, row, p);
                }
            }
        }
    
        return ds[resultColIndex][searchCol.length - 1];
    }
}

export default Dataset;