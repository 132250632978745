import React from 'react';

/**
 * Only render content on certain platforms
 * @param {*} props 
 * @returns 
 */

const PlatformRestricted = (props) => {
    const platform = process.env.REACT_APP_PLATFORM
    const hidden = props.allowed !== platform
    console.log(platform, props.allowed, hidden)
    return !hidden && props.children;
}

export default PlatformRestricted;