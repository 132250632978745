import React from 'react';

//https://developers.google.com/maps/documentation/maps-static/start
const StaticMap = props => {
    var location = props.location;

    const color = 'red';
    const size = '400x400';
    const mapType = 'roadmap';
    const key = process.env.REACT_APP_GMAP_API_KEY;
    const zoom = 8;
    var marker = null;

    if (location.point) {
         marker = `size:mid%7Ccolor:${color}%7Clabel:%7C${location.point.latitude},${location.point.longitude}`;
    } else if (location.region) {
        const region = location.region;
        var address = '';

        if (region.locality !== null) {
            address += region.locality;
        }

        if (region.adminDistrict2 !== null) {
            if (address) {
                address += ',';
            }
            address += region.adminDistrict2;
        }

        if (region.adminDistrict !== null) {
            if (address) {
                address += ',';
            }
            address += region.adminDistrict;
        }

        if (region.country !== null) {
            if (address) {
                address += ',';
            }
            address += region.country;
        }

        address = encodeURIComponent(address);

        marker = `size:mid%7Ccolor:${color}%7Clabel:%7C${address}`;
    }

    if (!marker) {
        return null;
    }

    return <img 
        src={`https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${size}&mapType=${mapType}&key=${key}&markers=${marker}`}
        style={{'maxWidth': '100%'}}
        />
}

export default StaticMap;