import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Container, Header } from 'semantic-ui-react';
import './App.css';
import CardCarousel from './CardCarousel';
import AdContent from './AdContent';
import CardScroll from './CardScroll';

const Images = props => {
    const scroll = true
    return (
        <Container fluid>
            <Header>Gallery</Header>
            {
                scroll
                    ? <CardScroll client={props.client} />
                    : <CardCarousel client={props.client} />
            }
            
            <AdContent id='ad' />
        </Container>
    )
}  

export default Images;