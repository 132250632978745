import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { Container, Form, Search } from 'semantic-ui-react';
import Hideable from './Hideable';

const FIND_PERSON = gql`
query Person($name:String) {
  person(name: $name) {
    id
    name
  }
}
`;

const PersonSearch = (props) => {
    const [name, setName] = useState(props.name);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    return <Form.Field>
            <Hideable hidden={!props.label}>
              <label>{props.label}</label>
            </Hideable>
            <Search
                    fluid
                    // category
                    loading={loading}
                    onResultSelect={(e, {result}) => {
                        setName(result.title);
                        setResults([]);
                        if (props.onChange) {
                            props.onChange(event(props.id, result));
                        }
                    }}
                    onSearchChange={e => {
                        var newValue = e.currentTarget.value;
                        setName(newValue);
                        // if (props.onChange) {
                        //     props.onChange(event(props.id, newValue));
                        // }
                        _.debounce(() => search(props.client, props.home, newValue, setLoading, setResults), 500, {leading:true})()
                    }}
                    resultRenderer={p => {
                        return <Container>{p.title}</Container>
                    }}
                    results={results}
                    value={name}
                    placeholder={props.placeholder}
                    attached='left'
                    action={props.action}
                    />
            </Form.Field>
}

const search = (client, home, v, setLoading, setResults) => {
    if (!v || v.length < 3) {
        setResults([]);
        return;
    }
    setLoading(true);
    client.query({
        query: FIND_PERSON,
        variables: { name: v }
      }).then(data => {
        if (data.data.person == null) {
          console.log('Search failed');
        } else {
          var results = [];
          
          if (data && data.data && data.data.person && data.data.person.length > 0) {
            data.data.person.map(p => {return {title: p.name, id: p.id}}).forEach(p => results.push(p))
          };
          
          setResults(results);
          setLoading(false);
        }
      }).catch(err => {
        console.log(err.message);
      })
}

const event = (k, v) => {
    return {preventDefault: () => {}, currentTarget: {id: k, value: v}};
}

export default PersonSearch;