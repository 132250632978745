import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import './App.css';
import Util from './Util';

const DateTimeInput = props => {
  const [date, setDate] = useState(dateStr(props.value));
  const [time, setTime] = useState(timeStr(props.value));

  return (
    <Form.Field>
        <label>{props.label}</label>
        <Form.Group widths='equal'>
            <Form.Field>
                <input type='date' id='date' value={date} onChange={e => changeState(e, props.id, setDate, props.onChange)}/>
            </Form.Field>
            <Form.Field>
                <input type='time' id='time' value={time} onChange={e => changeState(e, props.id, setTime, props.onChange)}/>
            </Form.Field>
        </Form.Group>
    </Form.Field>
  )
}

const dateStr = value => ymd(midnight(value))
const timeStr = value => hm(value)
const changeState = (e, id, setter, onChange) => {
  e.preventDefault();
  var k = e.currentTarget.id;
  var v = e.currentTarget.value;
  var date = k === 'date' ? v : Util.value('date');
  var time = k === 'time' ? v : Util.value('time');
  if (k === 'date') setter(date);
  if (k === 'time') setter(time);
  let value = date && time ? makeDate(date, time) : null;
  if (onChange) {
    onChange(value);
  }
}

function makeDate(date, time) {
    var offset = -new Date().getTimezoneOffset();
    var negative = offset < 0;
    if (negative) {
        offset = -offset;
    }
    var offsetHours = Math.floor(offset / 60);
    var offsetMinutes = offset - (offsetHours * 60);
    var dateStr = date + 'T' + 
        time + ':00' + 
        (negative ? '-' : '+') +
        (offsetHours < 10 ? '0' : '') + offsetHours + ':' +
        (offsetMinutes < 10 ? '0' : '') + offsetMinutes;
    return new Date(dateStr);
}

function midnight(date) {
    if (date == null) {
      return null;
    }
    var d = new Date(date.getTime());
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }
  
  function ymd(date) {
    if (date == null) {
      return null;
    }
    return date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate()
  }
  
  function hm(date) {
    if (date == null) {
      return null;
    }
    var h = date.getHours();
    var m = date.getMinutes();
    return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
  }

export default DateTimeInput;