import React, { useEffect } from 'react';


//https://developers.google.com/identity/gsi/web/guides/display-button
const SignInGoogle = props => {
    function handleCredentialResponse(response) {
        console.log("Encoded JWT ID token: " + response.credential);
        props.onSuccess?.('google', response.credential)
      }

    const onScriptLoad = () => {
        if (!window.google?.accounts) {
          return
        }
        window.google.accounts.id.initialize({
            client_id: "1050329103277-4ietv915up5vj5kbp8j7lukql94jq66b.apps.googleusercontent.com",
            callback: handleCredentialResponse
          });
          window.google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            { theme: "outline", size: "large" }  // customization attributes
          );
          window.google.accounts.id.prompt(); // also display the One Tap dialog
    }

    useEffect(() => {
        if (props.disabled) {
          return
        }
        if (!window.gsigninloading) {
            console.log("Load google sign in")
            window.gsigninloading = true
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://accounts.google.com/gsi/client`;
            script.id = 'GoogleSignIn';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            script.addEventListener('load', e => {
              console.log("GoogleSignIn loaded")
              onScriptLoad()
            })
          } else {
            onScriptLoad()
          }
    });

    return (<span>
        <div id="buttonDiv" />
    </span>)
}

export default SignInGoogle;